import { TranslateHtml } from '@change/core/react/i18n';
import { Link } from '@change/design-system/components/actions';
import { Text } from '@change/design-system/typography';

export function RespondAsNewDm({
	dmName,
	respondAsNewDmData: { ask, link },
	handleNewDmActionClick,
}: {
	dmName: string;
	respondAsNewDmData: {
		ask: string;
		link: string;
	};
	handleNewDmActionClick: () => void;
}): JSX.Element {
	return (
		<>
			{ask && link && (
				<Text size="small" fontWeight="normal">
					<TranslateHtml
						value="corgi.petition_gamma.decision_makers.response_status.are_you_dm"
						replacements={{ dmName }}
					/>{' '}
					<Link
						to={link}
						target="blank"
						color="primary-changeRed"
						data-qa="respond-as-new-dm"
						onClick={handleNewDmActionClick}
					>
						<Text size="small" fontWeight="normal">
							{ask}
						</Text>
					</Link>
				</Text>
			)}
		</>
	);
}
