import type { JSX } from 'react';

import { Box } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

import { usePetitionDetails } from '../hooks/petitionDetails/petitionDetails';

// TODO: Design System Issue
// This is a temporary solution using fontSize: [40, null, 48].
// We need to bring the design system in line with Gamma.
// This will need to be addressed to avoid design inconsistency in the future.

export function HeadingContainer(): JSX.Element {
	const details = usePetitionDetails();
	return (
		<Box py={24}>
			<Box>
				<Heading
					as="h1"
					pt={0}
					breakWord
					data-qa="petition-title"
					sx={{
						textAlign: 'left',
						lineHeight: '44px',
						fontSize: [40, null, 48],
					}}
				>
					{details.ask}
				</Heading>
			</Box>
		</Box>
	);
}
