import React from 'react';

import { Box, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import type { SignaturesCluster } from '../../shared/types';
import { PostalCodeValue } from '../PostalCodeValue';
import { SignatureDistributionIcon } from '../SignatureDistributionIcon';

type Props = {
	signaturesClusters: SignaturesCluster[];
	clusterPercentage: number;
};

export function SignatureDistributionSummary({ signaturesClusters, clusterPercentage }: Props): React.JSX.Element {
	const { contributors } = signaturesClusters[0];

	return (
		<Box
			sx={{
				overflowWrap: 'break-word',
				textAlign: 'left',
			}}
		>
			<Flex sx={{ gap: '20px' }}>
				<Box>
					<SignatureDistributionIcon fontSize={32} fill="none" strokeWidth={1.5} />
				</Box>
				<Flex sx={{ flexDirection: 'column', gap: '5px' }}>
					<Box>
						<Text size="large" fontWeight="bold" data-testid="cluster-percentage">
							{clusterPercentage.toFixed(0)}%
						</Text>
						<Text size="large">&nbsp;of supporters come from:</Text>
					</Box>
					<Flex data-qa="top-postal-code" data-testid="top-postal-code" sx={{ gap: '10px' }}>
						{contributors
							.filter((_, index) => index < 3)
							.map((e) => `${e.postalCode}`)
							.map((postalCode) => (
								<PostalCodeValue key={postalCode} postalCode={postalCode} />
							))}
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
}
