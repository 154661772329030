import { Box, Flex } from '@change/design-system/layout';

import type { SupporterComment, SupporterCommentPetition } from '../../shared/types';

import { SupporterCommentCardBody } from './components/SupporterCommentCardBody';
import { SupporterCommentCardFooter } from './components/SupporterCommentCardFooter';
import { SupporterCommentCardHeader } from './components/SupporterCommentCardHeader';

export function SupporterCommentCard({
	supporterComment,
	petition,
}: {
	supporterComment: SupporterComment;
	petition: SupporterCommentPetition;
}): React.JSX.Element {
	return (
		<Box variant="bordered" data-testid={`supporter-comment-${supporterComment.id}`} p={[16]} mb={[16]}>
			<Flex sx={{ gap: '10px', flexDirection: 'column' }}>
				<SupporterCommentCardHeader supporterComment={supporterComment} />

				<SupporterCommentCardBody supporterComment={supporterComment} />

				<SupporterCommentCardFooter supporterComment={supporterComment} petition={petition} />
			</Flex>
		</Box>
	);
}
