import { Text } from '@change/design-system/typography';

export function PostalCodeValue({ postalCode }: { postalCode: string }): React.JSX.Element {
	return (
		<Text
			size="small"
			sx={{
				backgroundColor: 'primary-greyBackground',
				borderRadius: '15px',
			}}
			px={8}
			py={4}
		>
			{postalCode}
		</Text>
	);
}
