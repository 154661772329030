import { useMemo } from 'react';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import type { DecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';

type Result = ModelHookResult<
	{
		decisionMakers: DecisionMaker[];
		totalCount: number;
	},
	EmptyObject
>;

// ** Sort Priority
// 1. YES publicly available, YES email
// 2. NO publicly available, YES email
// 3. YES publicly available, NO email
// 4. NO publicly available
const sortDecisionMakers = (unsorted: readonly DecisionMaker[]): DecisionMaker[] => {
	const sorted = [...unsorted];
	sorted.sort((x, y) => {
		const hasEmailX = x.contactInfo?.email ? 1 : 0;
		const hasEmailY = y.contactInfo?.email ? 1 : 0;

		const isVisibleX = x.publiclyVisible ? 1 : 0;
		const isVisibleY = y.publiclyVisible ? 1 : 0;

		if (hasEmailX !== hasEmailY) return hasEmailY - hasEmailX;
		if (isVisibleX !== isVisibleY) return isVisibleY - isVisibleX;
		return 0;
	});
	return sorted;
};

export function usePrimaryDecisionMakerConnection(): Result {
	const {
		data: {
			petition: {
				primaryDecisionMakerConnection: { nodes, totalCount },
			},
		},
	} = usePetitionDetailsPageData();

	const decisionMakers = useMemo(() => sortDecisionMakers(nodes), [nodes]);

	return {
		data: {
			decisionMakers,
			totalCount,
		},
		actions: {},
	};
}
