import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Button } from '@change/design-system/components/actions';
import { Box } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

import { ScrollTrackingWaypointContainer } from '../ScrollTrackingWaypoint';

import { DecisionMakerCard } from './components/DecisionMakerCard';
import { useDecisionMakerCards } from './hooks/useDecisionMakerCards';

export function DecisionMakerCardsContainer(): JSX.Element | null {
	const {
		data: { displayedDMs, displayShowAllButton, allDmResponses, shouldTrackDmEmailCtaView, remainingDMsCount },
		actions: { handleViewMoreClick },
	} = useDecisionMakerCards();
	const {
		data: {
			petition: { id },
		},
	} = usePetitionDetailsPageData();

	if (displayedDMs.length === 0) return null;

	return (
		<Box px={16} py={32} data-testid="decision-maker-cards">
			<Heading as="h2" size={['h3']} mb={40} id="decision-makers-heading">
				<Translate value="corgi.petition_gamma.decision_makers.section_title" />
			</Heading>

			{shouldTrackDmEmailCtaView && (
				<ScrollTrackingWaypointContainer name="petition_page_email_dm_view" trackingData={{ petition_id: id }} />
			)}

			{/* using role due to https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html */}
			{/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
			<ul role="list">
				{displayedDMs.map((decisionMaker) => (
					<li key={decisionMaker.id} sx={{ mb: 24 }}>
						<DecisionMakerCard key={decisionMaker.id} decisionMaker={decisionMaker} allDmResponses={allDmResponses} />
					</li>
				))}
			</ul>

			{displayShowAllButton && (
				<Button
					variant="secondary"
					mt={24}
					sx={{ width: '100%' }}
					onClick={handleViewMoreClick}
					data-qa="view-more-dms"
					data-testid="view-more-dms"
				>
					<Translate
						value="corgi.petition_gamma.decision_makers.view_more"
						replacements={{ numberOfDms: remainingDMsCount }}
					/>
				</Button>
			)}
		</Box>
	);
}
