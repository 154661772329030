import React, { useCallback } from 'react';

import { Translate } from '@change/core/react/i18n';
import { ButtonLink } from '@change/design-system/components/actions';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';

type Props = {
	petitionId: string;
	slug: string;
};

export function ViewAllComments({ petitionId, slug }: Props): React.JSX.Element {
	const { track } = useCampaignTracking();

	const trackViewAllClick = useCallback(() => {
		track('petition_gamma_supporter_comments_view_all_click', { petition_id: petitionId });
	}, [petitionId, track]);

	return (
		<ButtonLink
			data-testid="supporter-comments-view-all"
			to={`/p/${slug}/feed`}
			variant="secondary"
			my={5}
			sx={{ width: ['100%', 'auto'] }}
			onClick={trackViewAllClick}
		>
			<Translate value="corgi.petition_gamma.comments.view_all" />
		</ButtonLink>
	);
}
