import type { ComponentProps, PropsWithChildren } from 'react';

import { Translate, withI18nPlaceholders } from '@change/core/react/i18n';
import { InlineMessage } from '@change/design-system/components/alerts';
import { Loader } from '@change/design-system/components/progressiveDisclosure';
import { Box } from '@change/design-system/layout';
import { createModalHook } from '@change/design-system/modals';
import { Text } from '@change/design-system/typography';

import I18N_PLACEHOLDERS from 'config/i18n/placeholders/petitionGamma/petition-gamma.yml';

import {
	PetitionDetailsPageDataContextProvider,
	PetitionDetailsPageFcmContextProvider,
} from 'src/app/pages/petitionGamma/details/pageContext';
import { isError, isLoading } from 'src/app/shared/utils/async';

import { useApiDecisionMakers } from '../../../../../../hooks/decisionMakers';
import type { PetitionDetailsFcm } from '../../../../../../hooks/fcm';
import { DecisionMakerCard } from '../../index';

const TranslationPlaceholdersProvider = withI18nPlaceholders(I18N_PLACEHOLDERS, ({ children }: PropsWithChildren) => (
	<>{children}</>
));

export const useDecisionMakerCardsModal = createModalHook<{
	pageData: ComponentProps<typeof PetitionDetailsPageDataContextProvider>['pageData'];
	fcm: PetitionDetailsFcm;
}>({
	name: 'DecisionMakersModal',
	title: () => (
		<Text size="huge">
			<Translate value="fe.pages.petition_new.decision_maker" />
		</Text>
	),
	body: function DecisionMakersModalBody({
		options: {
			pageData: {
				data: {
					petition: {
						slug,
						primaryDecisionMakerConnection: { totalCount },
						dmResponsesConnection: { nodes: allDmResponses },
					},
				},
			},
		},
	}) {
		const apiDmResult = useApiDecisionMakers(slug, totalCount);

		if (isLoading(apiDmResult)) return <Loader size={['s', 'm']} mx="auto" my={16} />;

		if (isError(apiDmResult))
			return (
				<InlineMessage size="large" variant="error">
					<Translate value="fe.errors.generic_try_again" />
				</InlineMessage>
			);

		// TODO: remove when sorting moves to GQL
		// show dms with emails first
		const decisionMakers = [...(apiDmResult.decisionMakers || [])].sort((a, b) => {
			if (!!a.contactInfo?.email && !!b.contactInfo?.email) return 0;
			if (a.contactInfo?.email) return -1;
			return 1;
		});

		return (
			<>
				{decisionMakers.map((decisionMaker) => (
					// using role due to https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
					// eslint-disable-next-line jsx-a11y/no-redundant-roles
					<ul role="list" key={decisionMaker.id}>
						<li>
							<Box mb={24}>
								<DecisionMakerCard decisionMaker={decisionMaker} allDmResponses={allDmResponses} />
							</Box>
						</li>
					</ul>
				))}
			</>
		);
	},
	decorator: ({ children, options: { pageData, fcm } }) => {
		return (
			<PetitionDetailsPageDataContextProvider pageData={pageData}>
				<PetitionDetailsPageFcmContextProvider pageFcm={fcm}>
					<TranslationPlaceholdersProvider>{children}</TranslationPlaceholdersProvider>
				</PetitionDetailsPageFcmContextProvider>
			</PetitionDetailsPageDataContextProvider>
		);
	},
});
