import React from 'react';

import { ClientRender } from '@change/core/react/ssr/render';
import { Box, Flex } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

import { usePetitionDetailsPageData, usePetitionDetailsPageFcm } from 'src/app/pages/petitionGamma/details/pageContext';
import { UserIsInVariantOrStaffOrInSet } from 'src/app/shared/components/access';

import { useSupporterVideos } from '../../../../hooks/supporterVoices/supporterVideos';
import { CreateVideo } from '../CreateVideo';
import { VideoComponent } from '../VideoComponent';
import { ViewPetitionFeed } from '../ViewPetitionFeed';

export function SupporterVoicesVideos(): React.JSX.Element | null {
	const {
		data: {
			petition: { id: petitionId, slug },
			supporterVoicesData,
		},
	} = usePetitionDetailsPageData();

	const {
		data: { currentlyPlaying, supporterVideos, showCreateVideo },
		actions: { setCurrentlyPlaying, removeVideoFromState },
	} = useSupporterVideos({ supporterVoicesData });

	const { petitionFeedAccess } = usePetitionDetailsPageFcm();

	if (supporterVideos.length === 0) return null;

	return (
		<>
			<Heading as="h3" sx={{ fontSize: '16', fontWeight: 'normal' }} my={18}>
				Featured Videos
			</Heading>
			<Box
				py={[16, 0]}
				sx={{
					// This creates a scrolling carousel.
					maxWidth: '100%',
					overflowX: 'auto',
					overflowY: 'hidden',
					position: 'relative',
				}}
			>
				<Flex
					sx={{
						gap: 16,
					}}
				>
					{supporterVideos?.map((video) => {
						return (
							<VideoComponent
								video={video}
								currentlyPlaying={currentlyPlaying}
								key={video.id}
								setCurrentlyPlaying={setCurrentlyPlaying}
								removeVideoFromState={removeVideoFromState}
							/>
						);
					})}
					{showCreateVideo && <CreateVideo />}
				</Flex>
			</Box>

			{/* TODO(CHANGE-67840): replace with a check on a boolean set on pageUserData to avoid CLS */}
			<ClientRender>
				<UserIsInVariantOrStaffOrInSet featureAccessConfigValue={petitionFeedAccess} variant="feed" fallback={null}>
					<ViewPetitionFeed petitionId={petitionId} slug={slug} />
				</UserIsInVariantOrStaffOrInSet>
			</ClientRender>
		</>
	);
}
