/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';

import { Box } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

import { SupporterVoicesComments } from '../supporterVoicesComments';

import { SupporterVoicesVideos } from './components/SupporterVoicesVideos';
import { useSupporterVoicesSection } from './hooks/useSupporterVoicesSection';

export const SupporterVoices: React.FC = () => {
	const {
		data: { showSupporterVoicesSection },
	} = useSupporterVoicesSection();

	if (!showSupporterVoicesSection) {
		return <Box sx={{ display: 'none' }} data-testid="petition-page-supporters-voices-hidden" />;
	}

	return (
		<Box px={[16, 0]} mb={[24, 0]} data-testid="petition-page-supporters-voices">
			<Heading as="h2" sx={{ fontSize: '24' }} my={18}>
				The Supporters
			</Heading>

			<SupporterVoicesComments />

			<SupporterVoicesVideos />
		</Box>
	);
};
