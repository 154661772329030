import { useMemo } from 'react';
import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Html } from '@change/design-system/html';
import { Box } from '@change/design-system/layout';

import { useRichMediaTransformers } from 'src/app/shared/hooks/richMedia';

import { usePetitionDetails } from '../hooks/petitionDetails/petitionDetails';
import { useDescriptionTracking } from '../hooks/tracking/description';

export function DescriptionContainerViewportSmall(): JSX.Element {
	const { trackClickLink } = useDescriptionTracking();
	const details = usePetitionDetails();
	const { decodeRichMediaTransformer } = useRichMediaTransformers();

	const htmlTransformers = useMemo(() => [decodeRichMediaTransformer], [decodeRichMediaTransformer]);

	return (
		<Box>
			{details && details.description && (
				<Box>
					<h2>
						<Translate value="corgi.petition_gamma.details.description_title" />
					</h2>

					<Box data-qa="description-content">
						<Box data-testid="description-html-content">
							<Html
								breakWord
								html={details.description}
								sx={{
									iframe: {
										width: '100%',
										height: 'auto',
										aspectRatio: '16/9',
									},
								}}
								transformers={htmlTransformers}
								onClickLink={trackClickLink}
							/>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
}
