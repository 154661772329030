import { Separator } from '@change/design-system/components/content';
import { Box, Flex } from '@change/design-system/layout';

import { useContentSummary } from '../../hooks/useContentSummary';
import { DecisionMakersText } from '../DecisionMakersText';
import { MediaHitsText } from '../MediaHitsText';
import { UpdatesText } from '../UpdatesText';

import { MicrophoneIcon } from './icons/MicrophoneIcon';
import { NewspaperHeartMiddleIcon } from './icons/NewspaperHeartMiddleIcon';
import { USCapitolIcon } from './icons/USCapitolIcon';

type Props = EmptyObject;

export const ContentSummaryViewportSmall: React.FC<Props> = () => {
	const {
		data: { sectionIsShown, decisionMakers, mediaHits, updates },
	} = useContentSummary();

	if (!sectionIsShown) {
		return null;
	}

	return (
		<>
			<Separator role="presentation" mt={16} />
			<Box p={24}>
				<Flex
					data-testid="content-summary"
					sx={{
						gap: 16,
						flexDirection: 'column',
						justifyContent: 'space-around',
					}}
				>
					{decisionMakers.isShown && (
						<Flex sx={{ gap: 16 }} data-testid="content-summary-decision-makers">
							<USCapitolIcon fontSize={24} />
							<DecisionMakersText firstDmName={decisionMakers.first?.displayName} count={decisionMakers.count} />
						</Flex>
					)}
					{updates.isShown && (
						<Flex sx={{ gap: 16 }} data-testid="content-summary-updates">
							<NewspaperHeartMiddleIcon fontSize={24} />
							<UpdatesText count={updates.count} />
						</Flex>
					)}
					{mediaHits.isShown && (
						<Flex sx={{ gap: 16 }} data-testid="content-summary-media-mentions">
							<MicrophoneIcon fontSize={24} />
							<MediaHitsText count={mediaHits.count} />
						</Flex>
					)}
				</Flex>
			</Box>
			<Separator role="presentation" />
		</>
	);
};
