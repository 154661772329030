import { Separator } from '@change/design-system/components/content';
import { Flex } from '@change/design-system/layout';

import { usePetitionDetails } from '../../../../hooks/petitionDetails';
import { ScrollTrackingWaypointContainer } from '../../../ScrollTrackingWaypoint';
import { useSignaturesDistributionContext } from '../../shared/provider';
import { SignatureDistributionSummary } from '../SignatureDistributionSummary';
import { SignatureMap } from '../SignatureMap';

type Props = {
	isStaff: boolean;
};

export function SignaturesDistributionInfo({ isStaff }: Props): React.JSX.Element | null {
	const { id } = usePetitionDetails();
	const {
		data: { isLoadingData, isCrunchingData, signaturesClusters, clusterPercentage },
	} = useSignaturesDistributionContext();

	return (
		<>
			{!isLoadingData && !isCrunchingData && signaturesClusters && clusterPercentage !== undefined && (
				<Flex sx={{ flexDirection: ['column'] }} mt={16}>
					<ScrollTrackingWaypointContainer
						name="petition_sign_map_view"
						trackingData={{ petition_id: id, is_staff: isStaff ? 'true' : 'false' }}
					/>
					<SignatureMap
						signaturesClusters={signaturesClusters}
						clusterPercentage={clusterPercentage}
						isStaff={isStaff}
					/>
					<Separator role="presentation" my={24} />
					<SignatureDistributionSummary signaturesClusters={signaturesClusters} clusterPercentage={clusterPercentage} />
				</Flex>
			)}
		</>
	);
}
