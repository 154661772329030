import { useTracking } from '@change/core/react/tracking';

import { usePetitionDetailsPageData, usePetitionDetailsPageFcm } from 'src/app/pages/petitionGamma/details/pageContext';
import type { DecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';

import type { AllDmResponses } from '../../../../../hooks/useDecisionMakers';

type Result = ModelHookResult<
	{
		dmHasResponded: boolean;
		respondAsNewDm: {
			ask: string;
			link: string;
		};
		firstDmResponseUrl: string;
	},
	{
		handleReadResponseClick: () => void;
		handleNewDmActionClick: () => void;
	}
>;

type UseDmResponseArgs = {
	decisionMaker: DecisionMaker;
	allDmResponses: AllDmResponses;
};

export function useDmResponse({ decisionMaker, allDmResponses }: UseDmResponseArgs): Result {
	const { dmCardConfig } = usePetitionDetailsPageFcm();
	const {
		data: {
			petition: { id: petitionId, slug },
		},
	} = usePetitionDetailsPageData();
	const track = useTracking();

	const dmResponses = allDmResponses.filter((response) => response.decisionMaker?.id === decisionMaker.id);

	const firstDmResponseUrl = dmResponses.length > 0 ? `/p/${slug}/responses/${dmResponses[0].id}` : '';

	const handleReadResponseClick = () => {
		void track('petition_gamma_read_dm_response_click', {
			petition_id: petitionId,
		});
	};

	const handleNewDmActionClick = () => {
		void track('petition_gamma_new_dm_action', {
			petition_id: petitionId,
		});
	};

	const urlObj = dmCardConfig?.newDmCtaLink && new URL(dmCardConfig.newDmCtaLink);
	if (urlObj) urlObj?.searchParams?.append('utm_campaign', petitionId);

	return {
		data: {
			dmHasResponded: dmResponses.length > 0,
			firstDmResponseUrl,
			respondAsNewDm: {
				ask: dmCardConfig?.newDmCtaCopy || '',
				link: urlObj?.toString() || '',
			},
		},
		actions: { handleReadResponseClick, handleNewDmActionClick },
	};
}
