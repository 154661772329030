import { useEffect, useRef } from 'react';

import { Loader as GoogleApiLoader } from '@googlemaps/js-api-loader';

import { useTracking } from '@change/core/react/tracking';
import { Box } from '@change/design-system/layout';

import { googleMapsApiClientKey } from 'config/google';

import { usePetitionDetails } from '../../../../hooks/petitionDetails';
import { useSignaturesDistributionContext } from '../../shared/provider';
import type { SignaturesCluster } from '../../shared/types';

import { centerParametersBuilder } from './centerParametersBuilder';
import { mapParametersBuilder } from './mapParamtersBuilder';

const getGoogleApiLoader = () =>
	new GoogleApiLoader({
		apiKey: googleMapsApiClientKey,
		version: 'weekly',
		libraries: ['maps', 'marker'],
	});

async function initMap(
	element: HTMLElement,
	signaturesClusters: SignaturesCluster[],
	clusterSize: number,
	zoomLevel: number,
) {
	const { mapParameters } = mapParametersBuilder();
	const { centerParameters } = centerParametersBuilder();

	const google = await getGoogleApiLoader().load();
	const { Circle, Map } = google.maps;
	const map = new Map(element, mapParameters(signaturesClusters, zoomLevel));
	// eslint-disable-next-line no-new
	new Circle({ map, ...centerParameters(signaturesClusters, clusterSize) });
}
type Props = {
	signaturesClusters: SignaturesCluster[];
	clusterPercentage: number;
	isStaff: boolean;
};

export function SignatureMap({ signaturesClusters, clusterPercentage, isStaff }: Props): React.JSX.Element {
	const {
		data: { clusterSize, zoomLevel },
	} = useSignaturesDistributionContext();
	const { id } = usePetitionDetails();
	const track = useTracking();
	const mapRef = useRef(null);
	const { contributors } = signaturesClusters[0];
	const { postalCode } = contributors[0];

	useEffect(() => {
		if (clusterSize && zoomLevel) {
			void initMap(mapRef.current as unknown as HTMLElement, signaturesClusters, clusterSize, zoomLevel);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clusterSize, zoomLevel]);

	useEffect(() => {
		void track('petition_sign_map_enabled', {
			petition_id: id,
			cluster_percentage: clusterPercentage,
			is_staff: isStaff ? 'true' : 'false',
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box data-qa="supporter-map">
			<div
				title={`map centered around zipcode ${postalCode}`}
				data-testid="div-around-sign-map"
				ref={mapRef}
				sx={{
					width: '100%',
					height: 400,
					borderRadius: '10px',
				}}
			></div>
		</Box>
	);
}
