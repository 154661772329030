import { type JSX } from 'react';

import { Translate, useI18n } from '@change/core/react/i18n';
import { ButtonLink } from '@change/design-system/components/actions';
import { Box } from '@change/design-system/layout';

import { useEmailDecisionMaker } from './hooks/useEmailDm';

export function EmailDecisionMaker({ dmEmail, dmName }: { dmEmail?: string | null; dmName: string }): JSX.Element {
	const {
		data: { emailHref },
		actions: { handleEmailDmClick },
	} = useEmailDecisionMaker({ dmEmail, dmName });

	const { translate } = useI18n();

	return (
		<>
			{dmEmail ? (
				<Box mb={8}>
					<ButtonLink
						to={emailHref}
						target="blank"
						variant="secondary"
						aria-label={translate('corgi.petition_gamma.decision_makers.email_dm')}
						sx={{ fontSize: '12px', width: '100%' }}
						onClick={handleEmailDmClick}
						data-qa="email-dm-btn"
						data-testid="email-dm-btn"
					>
						<Translate value="corgi.petition_gamma.decision_makers.email_dm" />
					</ButtonLink>
				</Box>
			) : null}
		</>
	);
}
